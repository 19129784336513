import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import { RateOrder } from './RateOrder';
import { FindOrder } from './FindOrder';
import { RateProductPage } from './RateProductPage';
import { LinktreePage } from "./LinktreePage"
import { useEffect } from 'react';
import { RateExternal } from './RateExternal';
import { UnsubscribePage } from './UnsubscribePage';
import { NetPromoterScoreSurvey } from './NetPromoterScoreSurvey';

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Home url="https://www.konfidency.com.br" />} />
          <Route path="/rate/:customer/:orderToken/:orderId" element={<RateOrder />} />
          <Route path="/rate/:customer/:orderId/sku/:sku" element={<RateOrder />} />
          <Route path="/rate/:customer/:orderId/item/:sku" element={<RateOrder />} />
          <Route path="/rate/:customer/order" element={<FindOrder />} />
          <Route path="/rate/:customer/:sku" element={<RateProductPage />} />
          <Route path="/ext/:customer" element={<LinktreePage />} />
          <Route path="/ext/:customer/rate" element={<RateExternal />} />
          <Route path="/ext/:customer/:orderToken/:orderId" element={<RateOrder external={true} />} />
          <Route path="/unsubscribe/:customer/:notificationId" element={<UnsubscribePage />} />
          <Route path="/nps/:customer/:requestId" element={<NetPromoterScoreSurvey />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
    </Router>
  );
}

function Home(props) {
  const { url } = props;

  useEffect(() => {
    if(process.env.NODE_ENV === 'production')
      window.location.href = url;
 }, [url]);

  return (process.env.NODE_ENV === 'production') ? (
    <h5>Redirecionando...</h5>
  ) : (
    <>
      <h2>Development mode</h2>
    </>
  );
}

function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

export default App;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Ring } from "./Ring";

import "./UnsubscribePage.scss";
import loading from "./loading-inverse.gif";

export function UnsubscribePage(props) {
  const { customer, notificationId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(false);
  const [sent, setSent] = useState(false);
  const [notificationInfo, setNotificationInfo] = useState(null);

  const getData = async () => {
    setIsLoading(true);

    var customerData = await axios.get(
      `${process.env.REACT_APP_API_URL}/${customer}/info`
    );

    setCustomerInfo(customerData.data);

    var notification = await axios.get(
      `${process.env.REACT_APP_API_URL}/${customer}/notifications/subscription/${notificationId}`
    );

    setNotificationInfo(notification.data);

    setIsLoading(false);
  };

  const sendOptOut = async () => {
    setIsSubmitting(true);
    await axios.post(
      `${process.env.REACT_APP_API_URL}/${customer}/notifications/optOut/${notificationId}`
    );
    setIsSubmitting(false);
    setSent(true);
  };

  useEffect(() => {
    getData();
  }, [customer, notificationId]);

  return (
    <div className="unsubscribe-page">
      <div className="logo">
        <img src={customerInfo.logo} alt={customerInfo.name} />
      </div>
      <h1>
        Notificações sobre avaliações de produtos da loja {customerInfo.name}
      </h1>

      {isLoading && (
            <div className="loading-container">
              <Ring />
            </div>
          )}

      {notificationInfo && (
        <>
          {!sent ? (
            <div className="unsubscribe-form">
              {notificationInfo.email && (
                <p>E-mail: {notificationInfo.email}</p>
              )}
              {notificationInfo.phone && (
                <p>Telefone: {notificationInfo.phone}</p>
              )}

              <p>
                Você deseja parar de receber nossas mensagens de solicitação de
                avaliação por {notificationInfo.email ? "e-mail" : "WhatsApp"}?
              </p>

              <button
                className="btn-unsubscribe"
                onClick={() => {
                  sendOptOut();
                }}
              >
                não quero mais receber{" "}
                {notificationInfo.email ? "e-mails" : "mensagens"}{" "}
                {isSubmitting && <img alt="Carregando..." src={loading} />}
              </button>
            </div>
          ) : (
            <div className="success-message">
              sua solicitação foi atendida com sucesso
            </div>
          )}
        </>
      )}
    </div>
  );
}

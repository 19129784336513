// import './RateProductPage.css';

import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { RateProduct } from "./RateProduct";

export function RateProductPage() {
    const { customer, sku } = useParams();
    const [product, setProduct] = useState(null);
    const [customerInfo, setCustomerInfo] = useState(null);

    useEffect(() => {
        const getData = async () => {
            var data = await axios.get(`${process.env.REACT_APP_API_URL}/${customer}/${sku}/details`);
            setProduct({ product: data.data, sku, customer });

            var customerData = await axios.get(`${process.env.REACT_APP_API_URL}/${customer}/info`);
            setCustomerInfo(customerData.data);
        }

        getData();
    }, [sku, customer])

    return (
        <div className="rate-product-page">
            {product && (
                <>
                    {customerInfo && customerInfo.importCssWebApp && (<link rel="stylesheet" href={customerInfo.importCssWebApp} />)}
                    <RateProduct product={product} customerInfo={customerInfo} />
                </>
            )}
        </div>
    )
}
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import "./RateOrder.css";

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import useQuery from "./useQuery";
import { Ring } from "./Ring";

export function FindOrder(props) {

  const { customer } = useParams();
  const query = useQuery();

  const getOrderDetails = async () => {
    const orderId = query.get('orderId')
    const email = query.get('email')
    const source = query.get('source')

    const sourceQS = source ? `?source=${source}` : ''

    const {data} = await axios.get(
      `${process.env.REACT_APP_API_URL}/${customer}/orders/find?orderId=${orderId}&email=${email}`
    );

    if(data) {
      window.location.href = `/rate/${customer}/${data.verificationTokenId}/${data.orderId}${sourceQS}`
    }
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  return (
    <div className="rate-order-page">
      <div className="loading-container">
        <Ring />
      </div>
    </div>
  );
}

import './StarsRating.css';

import React, { useEffect, useState, useImperativeHandle } from "react";
import { useParams } from "react-router-dom"
import axios from 'axios';

const StarsRating = React.forwardRef(({ enabled, value, onChange, onClickDisabled, showText=true}, ref) => {
    useImperativeHandle(ref, () => ({
        clear() {
          setRating(0);
          setActiveRating(0);
        },

        setRating(rating) {
            setRating(rating);
            setActiveRating(rating);
        }
      }))
// const {enabled, value, onChange, showText } = props;

    const clickText = 'ontouchstart' in window ? 'toque' : 'clique';
    const ratingText = () => {
        switch (activeRating) {
            case 0:
                return '';
            case 1:
                return 'péssimo';
            case 2:
                return 'ruim';
            case 3:
                return 'regular';
            case 4:
                return 'bom';
            case 5:
                return 'excelente';
        }
    }

    const [activeRating, setActiveRating] = useState(0);
    const [rating, setRating] = useState(0);

    useEffect(() => {
        if(value) {
            setRating(value);
            setActiveRating(value);
        }
    }, [value]);

    useEffect(() => {
        if(onChange)
            onChange(rating);
    }, [rating])


    return (
        <div className="rating-container">
            {enabled && (<span className="description">{clickText} nas estrelas para classificar</span>)}
            <div className="stars-rating">
                <button
                    type="button"
                    className={`star ${activeRating >= 1 ? 'active' : ''}`}
                    onMouseOver={() => { enabled && setActiveRating(1) }}
                    onMouseOut={() => { enabled && setActiveRating(rating) }}
                    onClick={() => { enabled ? setRating(1) : onClickDisabled && onClickDisabled(1) }}>
                    <img src="https://reviews.konfidency.com.br/img/star-icon.svg" />
                </button>
                <button
                type="button"
                    className={`star ${activeRating >= 2 ? 'active' : ''}`}
                    onMouseOver={() => { enabled && setActiveRating(2) }}
                    onMouseOut={() => { enabled && setActiveRating(rating) }}
                    onClick={() => { enabled ? setRating(2) : onClickDisabled && onClickDisabled(2) }}>
                    <img src="https://reviews.konfidency.com.br/img/star-icon.svg" />
                </button>
                <button
                type="button"
                    className={`star ${activeRating >= 3 ? 'active' : ''}`}
                    onMouseOver={() => { enabled && setActiveRating(3) }}
                    onMouseOut={() => { enabled && setActiveRating(rating) }}
                    onClick={() => { enabled ? setRating(3) : onClickDisabled && onClickDisabled(3) }}>
                    <img src="https://reviews.konfidency.com.br/img/star-icon.svg" />
                </button>
                <button
                type="button"
                    className={`star ${activeRating >= 4 ? 'active' : ''}`}
                    onMouseOver={() => { enabled && setActiveRating(4) }}
                    onMouseOut={() => { enabled && setActiveRating(rating) }}
                    onClick={() => { enabled ? setRating(4) : onClickDisabled && onClickDisabled(4) }}>
                    <img src="https://reviews.konfidency.com.br/img/star-icon.svg" />
                </button>
                <button
                type="button"
                    className={`star ${activeRating >= 5 ? 'active' : ''}`}
                    onMouseOver={() => { enabled && setActiveRating(5) }}
                    onMouseOut={() => { enabled && setActiveRating(rating) }}
                    onClick={() => { enabled ? setRating(5) : onClickDisabled && onClickDisabled(5) }}>
                    <img src="https://reviews.konfidency.com.br/img/star-icon.svg" />
                </button>
            </div>
            {showText && (<div className="rating">{ratingText()}</div>)}
        </div>
    )
});

export { StarsRating };
import "./NpsReviewTopics.scss";
import positiveReview from "./review-topic-positive.svg";
import neutralReview from "./review-topic-neutral.svg";
import negativeReview from "./review-topic-negative.svg";
import axios from "axios";

function ReviewTopicItem({ children }) {
  return <div className="review-topic-item">{children}</div>;
}
export function NpsReviewTopics({ survey, reviewedTopics, onScoreClick }) {
  function handleScoreClick(topic) {
    return function (event) {
      onScoreClick &&
        onScoreClick({
          _id: topic._id,
          description: topic.description,
          score: event.target.getAttribute("data-id"),
        });
    };
  }

  function isSelected(topic, score) {
    const topicSelected = reviewedTopics.find(
      (item) => item.description === topic.description
    );
    return parseInt(topicSelected?.score) === parseInt(score);
  }
  return (
    <div>
      <p>Como você avaliaria os seguintes tópicos</p>
      {(survey.topics || [])
        .filter((topic) => topic.active)
        .map((topic, index) => {
          return (
            <ReviewTopicItem key={index}>
              <div>{topic.description}</div>
              <div className="review-topic-score">
                <div className={isSelected(topic, 1) ? "selected" : ""}>
                  <img
                    src={negativeReview}
                    alt="Ruim"
                    data-id={1}
                    onClick={handleScoreClick(topic)}
                  />
                </div>
                <div className={isSelected(topic, 2) ? "selected" : ""}>
                  <img
                    src={neutralReview}
                    alt="Neutro"
                    data-id={2}
                    onClick={handleScoreClick(topic)}
                  />
                </div>
                <div className={isSelected(topic, 3) ? "selected" : ""}>
                  <img
                    src={positiveReview}
                    alt="Bom"
                    data-id={3}
                    onClick={handleScoreClick(topic)}
                  />
                </div>
              </div>
            </ReviewTopicItem>
          );
        })}
    </div>
  );
}

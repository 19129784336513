// import './RateProductPage.css';
import "bootstrap-icons/font/bootstrap-icons.css"
import "./LinktreePage.scss";

import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"

import stars from "./stars.png"

export function LinktreePage() {
    const { customer } = useParams();
    const [linktree, setLinktree] = useState(null);
    const [customerInfo, setCustomerInfo] = useState(null);
    const [cssVariables, setCssVariables] = useState({ "--bg-color1": "#dc239b", "--bg-color2": "#6A0F4A" });

    useEffect(() => {
        if (linktree) {
            setCssVariables({
                "--bg-color1": linktree.colors.backgroundColor1,
                "--bg-color2": linktree.colors.backgroundColor2
            })
        }
    }, [linktree])

    useEffect(() => {
        const getData = async () => {
            var { data } = await axios.get(`${process.env.REACT_APP_API_URL}/${customer}/linktree`);

            setLinktree(data);



            var customerData = await axios.get(`${process.env.REACT_APP_API_URL}/${customer}/info`);
            setCustomerInfo(customerData.data);
        }

        getData();
    }, [customer])

    return (
        <>
            {linktree && (
                <div className="linktree-page" style={cssVariables}>
                    <div className="inner">

                        <div className="linktree">
                            <div className="logo"><img src={linktree.logo} /></div>
                            {linktree.links.map((link) => {
                                return (
                                    <a href={link.link} className="link">
                                        <div className="link">

                                            <div className="icon"><i className={`bi bi-${link.icon}`}></i></div>
                                            <div className="content">
                                                <div className="line title">{link.text1}</div>
                                                <div className="line subtitle">{link.text2}</div>
                                            </div>

                                        </div>
                                    </a>
                                )
                            })}
                        </div>
                    </div>

                    <Link to={`/ext/${customer}/rate`} className="rate">

                        <h3>Avalie sua compra</h3>
                        <img className="stars" src={stars} />
                        <div className="promotionText">{linktree.ratePromotionText}</div>
                    </Link>


                </div>
            )}


        </>
    )
}